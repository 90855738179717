@import "./_theme_colors";
@import "./_theme_font";
@import "./_theme_custom";
@import "./_dates";
//
@import "./header";

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

.slider-ltr, .DateRangePicker_picker, .DayPicker {
    direction: ltr !important;
}

.font-amenities i{
    font-size: 70px;
}

.styles-module_whatsappButton__IWx9V{
    z-index: 11111 !important;
    bottom: 4rem !important;
}

.accordian-hotel{
    border-radius: 1rem !important;
    box-shadow: none !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
    background-color: transparent !important;
}
.add-service .focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 0px !important;
}