// NOTE: the order of these styles DO matter

.DateInput_fang {
  display: none;
}

.DateRangePicker {
  @apply w-full mt-auto h-full;
}

.DateRangePickerInput {
  background-color: transparent;
}

.DateRangePickerInput_arrow {
  @apply hidden;
}

body .DateRangePickerInput_clearDates,
body .SingleDatePickerInput_clearDate {
  @apply z-30 w-6 h-6 p-0 flex items-center justify-center;
}

.DateInput {
  @apply z-10 opacity-0 border border-red-500;
  input {
    @apply h-full w-full;
  }
}

.StayDatesRangeInput,
.ExperiencesDateSingleInput {
  .DateInput {
    @apply absolute top-0 h-1/2 lg:h-full lg:bottom-0 left-0 w-full lg:w-1/2;
    & ~ .DateInput {
      @apply top-auto lg:top-0 bottom-0 lg:left-auto right-0;
    }
  }
}

.RentalCarDatesRangeInput {
  @apply flex;
  .DateInput {
    @apply absolute top-0 bottom-0 left-0 w-1/2 z-20;
    & ~ .DateInput {
      @apply top-0 bottom-0 left-auto right-0;
    }
  }
  &.RentalCarDetailPageDatesRangeInput {
    .DateInput {
      @apply absolute top-0 h-1/2 left-0 w-full z-20;
      & ~ .DateInput {
        @apply top-auto bottom-0 right-0;
      }
    }
  }
}

.nc-ListingStayDetailPage__stayDatesRangeInput {
  .DateInput {
    @apply absolute top-0 bottom-0 h-full left-0 w-1/2 z-20;
    & ~ .DateInput {
      @apply top-0 bottom-0 left-auto right-0;
    }
  }
}

.nc-date-not-focusedInput {
  .DateRangePickerInput .DateRangePickerInput_clearDates,
  .SingleDatePickerInput_clearDate {
    opacity: 0 !important;
  }
}

.DateRangePickerInput > * {
  &.DateRangePickerInput_clearDates {
    @apply z-20;
  }
}

//
.SingleDatePicker {
  @apply w-full mt-auto h-full;
  .DateInput {
    @apply w-full h-full inset-0 #{!important};
  }
  .SingleDatePickerInput_clearDate {
    @apply z-20;
  }
}

.SingleDatePickerInput {
  background: transparent;
}
//

.DayPickerNavigation_button__horizontalDefault {
  @apply p-0 flex items-center justify-center w-9 h-9 rounded-full bg-transparent text-neutral-700 dark:text-neutral-200 focus:bg-transparent;
  svg {
    fill: currentColor;
  }
}

.DayPickerNavigation_svg__horizontal {
  @apply w-4 h-4;
}

.CalendarMonth_caption {
  @apply mb-3;
}
.CalendarMonth_caption strong {
  @apply font-semibold text-neutral-800 dark:text-neutral-100;
}
.DayPicker_weekHeader {
  @apply text-neutral-500 dark:text-neutral-400;
}
.DateRangePicker_picker,
.SingleDatePicker_picker {
  @apply top-full mt-3 #{!important};
}

.SingleDatePicker_picker,
.DateRangePicker_picker,
.CalendarMonthGrid,
.CalendarMonth {
  background-color: transparent !important;
}
.DayPicker__withBorder,
.DateRangePicker_picker {
  @apply bg-white dark:bg-neutral-800 shadow-xl rounded-3xl #{!important};
}

.CalendarDay__default.CalendarDay__blocked_calendar,
.CalendarDay__default.CalendarDay__blocked_out_of_range,
.CalendarDay__default.CalendarDay__blocked_out_of_range:active,
.CalendarDay__default.CalendarDay__blocked_out_of_range:hover {
  @apply border border-transparent hover:bg-transparent text-neutral-300 dark:text-neutral-500 cursor-default;
}

// Will edit everything selected including everything between a range of dates
.CalendarDay__default {
  @apply w-12 h-12 border border-transparent bg-transparent text-neutral-800 dark:text-neutral-200;
  &:hover {
    @apply bg-neutral-200 border border-neutral-200 dark:bg-neutral-700 dark:border-neutral-700;
  }
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  @apply bg-neutral-800 dark:bg-neutral-300 border border-neutral-800 text-neutral-100 dark:text-neutral-900;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span,
.CalendarDay__selected_span {
  @apply bg-neutral-100 dark:bg-neutral-700 border border-neutral-100 dark:border-neutral-700 text-neutral-700;
}

.CalendarDay__selected_span:hover {
  @apply bg-neutral-200 dark:bg-neutral-6000 border-neutral-200 dark:border-neutral-6000 text-neutral-700 dark:text-neutral-100;
}

//
.listingSection__wrap__DayPickerRangeController {
  .DayPicker__withBorder {
    @apply shadow-none mx-auto xl:ml-0  border-neutral-200 dark:border-neutral-700 rounded-xl dark:bg-transparent;
  }
}

//
.nc-SetYourAvailabilityData {
  .CalendarDay__highlighted_calendar {
    @apply bg-neutral-200 dark:bg-neutral-6000 border-neutral-200 dark:border-neutral-6000 text-neutral-700 dark:text-neutral-100 relative;
    &::after {
      content: "";
      width: 1px;
      height: 30px;
      left: 50%;
      transform: rotate(-40deg);
      background-color: black;
      position: absolute;
    }
  }
}

//
.HeroSearchFormMobile__Dialog {
  .DayPicker {
    @apply min-w-full shadow-none;
  }

  .CalendarMonth_caption {
    @apply text-base pt-4;
  }

  .DayPickerNavigation_button__verticalDefault {
    @apply inline-flex items-center justify-center shadow-none dark:bg-neutral-800 dark:border-neutral-700;
    svg {
      @apply w-6 dark:fill-white;
    }
  }
}

/* banner css */
.royal-text {
  display: inline-block;
  position: relative;
  z-index: 99;
  color: #000 !important;
  font-size: 23px !important;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 20px 50px !important;
  border-radius: 50px;
  margin: 20px;
}
.banner-slider-main {
  padding-top: 0 !important ;
  border-radius: 0;
  margin-top: -105px;
}
.banner-slider-main  img.absolute {
  border-radius: 0;
}

.banner-slider-new  {
    top: 40% !important;
    transform: translate(-50%, -50%);
    width: 75%;
    left: 50% !important;
}
.banner-slider-new span.font-medium.text-4xl.md\:text-4xl.xl\:text-4xl.\!leading-\[114\%\].text-neutral-900 {
  font-size: 48px;
  text-transform: capitalize;
  font-weight: 700;
}
.banner-dots  {
  bottom: 20px !important;
}
.banner-dots button.glide__bullet {
  width: 8px;
  height: 8px;
}
.banner-dots button.glide__bullet.w-1\.5.h-1\.5.rounded-full.bg-neutral-300.glide__bullet--active{
  width: 30px;
}
.banner-slider-main  .gallerySlider__StayCard_nc__rb_ {
  height: 80vh;
}



.check-out-new {
  margin-top: -80px;
  margin-bottom: 50px;
}
.check-out-new  .rounded-full {
  border-radius: 0 !important;
}

/* amenities css*/
.amenities-main {
  grid-gap: 20px;
}
.amenities-main .text-center {
  padding: 30px;
  background: #f7f7f7!important;
  box-shadow: 0 0 6px #33333359;
  color: #000;
}
.amenities-main .font-amenities i {
  color: #000;
  margin-bottom: 20px;
  font-size: 35px;
}
.amenities-main .text-center div {
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  color: #000;
}

.business-main {
  border: 1px solid #eee;
  padding: 30px;
  box-shadow: 0 0 5px rgb(0 0 0 / 10%);
}

.listingSectionSidebar-text  span.block.mt-1.text-sm.text-neutral-400.leading-none.font-light {
  color: #000;
  font-weight: 500;
}
.listingSectionSidebar-text button.nc-Button {
  border-radius: 0;
  display: inline-block;
  // width: fit-content;
  padding: 10px 45px;
}
.reserve-btn{
  background-color: rgba(var(--c-primary-600), var(--tw-bg-opacity));
  color: #fff !important;
  padding: 0.7em 4em !important;
  border: rgba(var(--c-primary-600), var(--tw-bg-opacity));
  font-size: 1.3rem !important;
}
.stay-listing-btn-main h2.font-medium.capitalize.text-lg {
  font-size: 2em;
  line-height: 1.3em;
}
.h-full.w-full.flex.flex-col.sm\:flex-row.stay-listing-btn-main {
  border: 1px solid rgba(0, 0, 0, 0.329);
}
.stay-listing-btn-main span.text-base.font-semibold {
  padding-top: 35px !important;
  display: block;
  margin-bottom: 15px;
  font-size: 1.3rem;
}
span.line-clamp-1.sub-abouts-title {
  font-weight: 500;
  font-size: 1.3em;
}
.normal-fonts-btn {
  font-size:0.8em !important;
  color: #000 !important;
  font-weight: 500 !important;
}


.abouts-main .flex-grow {
  margin: 0 !important;
  max-width: 100%;
}
.abouts-main  .flex.flex-col.lg\:flex-row.space-y-14.lg\:space-y-0.lg\:space-x-10.items-center.relative.text-center.lg\:text-left {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 50px;
}
.abouts-main .w-screen.max-w-full.xl\:max-w-lg.space-y-5.lg\:space-y-7 {
  width: 100%;
  max-width: 100%;
}


/* new css */
.margin-top {
  margin-top: 0 !important;
}
.standard-title-main .title-text{
  font-size: 1.6em;
   font-weight: 600;
   text-transform: capitalize;
   color: #000;
   margin-bottom: 15px;
}
.standard-title-main span.text-base {
 font-size: 1.2em;
 text-transform: capitalize;
}
.standard-title-main {
  padding: 0 !important;
  margin-top:40px;
}
.width-new {
  width: 100% !important;
}

.form-main {
  max-width: 100% !important;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  vertical-align: text-top;
  grid-gap: 15px;
}
.form-main .form-group {
  margin: 0 !IMPORTANT;
}
.form-main span.text-neutral-800 {
  font-size: 1.2em;
  font-weight: 500;
}

.border-top{
  margin-top: 15px;
}
.margin-0{
  margin-top:0 !important;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}

.margin-top .nc-Section-Heading {
  margin-bottom: 25px;
}
.date-title{
  font-size: 12px;
  font-size: 12px;
  color: #00b6d4;
  font-weight: 600;
}
.prices-subtitle {
  color:#0e92ff;
  font-weight: 600;
  font-size: 9px !important;
  line-height: 1.3em;
}
.DayPicker.DayPicker_1.DayPicker__horizontal.DayPicker__horizontal_2.DayPicker__withBorder.DayPicker__withBorder_3 {
  background-color: #fff !important;
  z-index: 0;
}

@media  (max-width:767px) {
   .banner-slider-new span.font-medium.text-4xl.md\:text-4xl.xl\:text-4xl.\!leading-\[114\%\].text-neutral-900 {
       font-size: 16px !IMPORTANT;
    }

   .royal-text {
      font-size: 1.1em !important;
      padding: 0.8em 2em !important;
      border-radius: 0 !important;
      margin: 15px !important;
  }
  .banner-slider-main .gallerySlider__StayCard_nc__rb_ {
     height: auto;
  }
  .abouts-main .flex.flex-col.lg\:flex-row.space-y-14.lg\:space-y-0.lg\:space-x-10.items-center.relative.text-center.lg\:text-left {
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0;
  }
  .abouts-main  .flex-grow img {
      margin-top: 20px !important;
  } 
}